/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from '@angular/core';
//import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';
import { environment } from '../environments/environment';
import {CommonService} from './common.service';
import { fromEvent, Observable, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class SocketTsService {
  public socket1: Socket;
  private data$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  constructor(public cs:CommonService) {
    this.socket1 = io(environment.hosturl, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      autoConnect:false,
      //reconnectionAttempts: 3
    });

    this.socket1.on('connect', async () => {
      this.sendMessgae('session',{uid:this.cs.userSession.user_id});
    });
    this.socket1.on('data', async (data: any) => {
        
        this.cs.handleSockComunication(data);
      });
    this.socket1.on('disconnect', async () => {
    });
  };
  startSession(){
    this.socket1.connect();
  }
  endSession(){
    try {
        this.socket1.disconnect();
    } catch (error) {
        
    }
   
  }
  sendMessgae(msgtype:any,msg: any) {
    this.socket1.emit(msgtype, msg);
  }
//   getData(): Observable<any> {
//     this.cs.handleSockComunication
//     return this.data$.asObservable();
//   }
  getDisconnection() {
    return new Observable(observer => {
      this.socket1.on('disconnect', async () => {
        observer.next({});
      });
    });
  }
}
